import React from 'react';
import { Link} from 'react-router-dom';




function Footer() {
    return (
        <div className="footer-main">
              <footer className="footer-section">
                <div className="container">
                <div className="row">
                  <div className="col-md-3 col-lg-3 pr-lg-5">
                    <h3 className="mb-4">About Us</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                  </div>
                  <div className="col-md-3 col-lg-3 pt-4 pt-md-0">
                    <h3 className="mb-4">Contact Us</h3>
                    <ul className="list-unstyled">
                      <li className="media">
                        <i className="fa fa-phone" />
                        <div className="media-body">
                          <a href="tel:0123-456-7890">0123-456-7890</a>
                        </div>
                      </li>
                      <li className="media">
                        <i className="fa fa-envelope" />
                        <div className="media-body">
                          <a href="mailto:mail@website.com">mail@website.com</a>
                        </div>
                      </li>
                      <li className="media">
                        <i className="fa fa-map-marker" />
                        <div className="media-body">
                          <p>2105  Warner Street
                            Cheyenne, WY
                            82001, USA
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-lg-3 text-center  mt-3 mt-md-0">
                    <h3>Follow Us</h3>
                    <ul className="social-link">
                      <li>
                        <Link to="/"><i className="fa fa-facebook" /></Link>
                      </li>
                      <li>
                        <Link  to="/"><i className="fa fa-instagram" /></Link>
                      </li>
                      <li>
                        <Link  to="/"><i className="fa fa-twitter" /></Link>
                      </li>
                      <li>
                        <Link to="/" ><i className="fa fa-linkedin" /></Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-lg-3 imp-link">
                    <h3 className="mb-3 mb-md-4 mt-3 mt-md-0">Important Links</h3>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/" >COMMUNICATION</Link>
                      </li>
                      <li>
                        <Link to="/investor">INVESTOR INFORMATION</Link>
                      </li>
                      <li>
                        <Link to="/company">COMPANIES</Link>
                      </li>
                      <li>
                        <Link to="/">KNOWLEDGEBASE</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </footer>
            <div className="copy-right">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>Copyright © 2021 Pill Dispenser 0. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>

        </div>
    );
}

export default Footer;