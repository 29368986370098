import React from 'react';
import { Link } from 'react-router-dom';



function Donate() {
    return (
        <div className="donate-page-main">

                <main>
                    <section className="donate-wraper pd-50">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-12">
                                <h5 className="donate-title">DONATE</h5>
                                <h5 className="my-4 text_primery donate-title-sub">DONATION DETAILS</h5>
                                <small className="mb-2 d-block"><span className="text-danger">*</span> required fields</small>
                            </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                            <div className="col-12">
                                <div className="donation-amount bg-success-light">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-6 col-md-6">
                                    <div className="form-group mt-3 mb-4">
                                        <label>YOUR DONATION AMOUNT <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control col-6 col-lg-3" placeholder="$450" /> 
                                    </div>
                                    <div className="chack-box-list">
                                        <p>How often would you like to make this donation?</p>
                                        <ul>
                                        <li>
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="donation" id="once" defaultChecked />
                                            <label className="form-check-label" htmlFor="once"> Once </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="donation" id="weekly" />
                                            <label className="form-check-label" htmlFor="weekly"> Weekly </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="donation" id="monthly" />
                                            <label className="form-check-label" htmlFor="monthly"> Monthly </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="donation" id="quarterly" />
                                            <label className="form-check-label" htmlFor="quarterly"> Quarterly </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="donation" id="bianually" />
                                            <label className="form-check-label" htmlFor="bianually"> Bianually </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="donation" id="anually" />
                                            <label className="form-check-label" htmlFor="anually"> Anually </label>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-5 col-md-6">
                                    <div className="about-to-donate text-center">
                                        <h2>YOU ARE ABOUT TO DONATE</h2>
                                        <div className="about-to-box mb-3">
                                        <h1>50</h1>
                                        <p>Pill Dispenser Modules to the poor people in Burundi</p>
                                        </div>
                                        <p className="text-gray">Adjust your donation amount to change the number of device you want to donate</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="donate-form pt-5">
                        <div className="container">
                        <div className="row d-flex justify-content-between my_row">
                            <div className="col-xl-5 col-lg-6 col-md-6">
                            <h4 className=" text_primery donate-title-sub">CONTACT INFORMATION</h4>
                            <div className="form-group mt-3 mb-3">
                                <label>Email<span className="text-danger">*</span></label>
                                <input type="Email" className="form-control" /> <small>You will be able to create an account after purchasing</small> 
                            </div>
                            <div className="form-group mt-3">
                                <label>Phone </label>
                                <input type="text" className="form-control" /> 
                            </div>
                            <h4 className=" text_primery donate-title-sub">BILLING DETAILS</h4>
                            <small><span className="text-danger">*</span>required fields</small>
                            <div className="form-group mt-3 mb-3">
                                <label>Full Name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" /> 
                            </div>
                            <div className="form-group  mb-3 mt-md-0">
                                <label>Country<span className="text-danger">*</span></label>
                                <select className="form-control ">
                                <option />
                                </select>
                            </div>
                            <div className="form-group position-relative mt-3">
                                <label>Street Address<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" />
                                <label className="cutom-lable">Street Address is required</label> <small className="mt-3 d-block">+ Add another address field (optional)</small> 
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                <div className="form-group">
                                    <label>Postcode / Zip<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" /> 
                                </div>
                                </div>
                                <div className="col-md-4 pt-4 mt-3"> <small>Enter zip for City &amp; State</small> </div>
                            </div>
                            <div className="add-item">
                                <h4 className=" text_primery donate-title-sub">ADDITIONAL OPTIONS</h4>
                                <small className="d-block">+ Add a note</small> 
                            </div>
                            <h6 className="text_primery ">PAYMENT METHOD</h6>
                            <div className="card credit-card">
                                <div className="card-body bg-white">
                                <div className="row   d-flex align-items-center">
                                    <div className="col-md-7">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Quarterly" id="Credit Card " defaultValue="option4" />
                                        <label className="form-check-label" htmlFor="Credit Card"> Credit Card </label>
                                    </div>
                                    </div>
                                    <div className="col-md-5"> <img className="img-fluid" src="assets/images/credit-card.png" alt="Card" /> </div>
                                </div>
                                <div className="row no-gutters mt-2">
                                    <div className="col-6">
                                    <input type="text" className="form-control" placeholder="Card Number" /> 
                                    </div>
                                    <div className="col-3">
                                    <input type="text" className="form-control" placeholder="MM / YY" /> 
                                    </div>
                                    <div className="col-3">
                                    <input type="text" className="form-control" placeholder="CVV" /> 
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="read_info">
                                <div className="form-group form-check mb-0">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">I have read and agree to the website terms and conditions <span className="text-danger">*</span> </label>
                                </div>
                                <p>Your personal data will be used to process your donation, support your experience throughout this website, and for other purposes described in our <Link to="/">privacy policy</Link></p> 
                            </div>
                            <div className="more-question">
                                <h4 className=" text_primery donate-title-sub">A FEW MORE QUESTION</h4>
                                <div className="form-group form-check mb-0">
                                <input type="checkbox" className="form-check-input" id="exampleCheck2" />
                                <label className="form-check-label" htmlFor="exampleCheck2">Would you like to dedicate this donation to someone? </label>
                                </div>
                                <div className="form-group mt-3 mb-3">
                                <label>Dedication name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" /> 
                                </div>
                                <div className="form-group mt-3 mb-3">
                                <label>Dedication email<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" /> 
                                </div>
                                <div className="form-group form-check mt-2">
                                <input type="checkbox" className="form-check-input" id="exampleCheck3" />
                                <label className="form-check-label" htmlFor="exampleCheck3">Check this if you want to notify the person who you want t o dedicate to </label>
                                </div>
                                <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck4" />
                                <label className="form-check-label" htmlFor="exampleCheck4">Share my name and contact info with the charities I suppor t, so that they can email me. </label>
                                </div>
                                <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck5" />
                                <label className="form-check-label" htmlFor="exampleCheck5">Check this box if you want to subscribe to our Newsletter </label>
                                </div>
                                <button type="button" className="btn btn-warning mt-4 px-5">MAKE DONATION</button>
                            </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-6 payment-methods">
                            <div className="card">
                                <div className="card-header">
                                <h2>OTHER PAYMENT METHODS</h2>
                                </div>
                                <div className="card-body">
                                <ul>
                                    <li><Link to="/" className="active">CREDIT CARD</Link></li>
                                    <li><Link to="/">PAYPAL</Link></li>
                                    <li><Link to="/">CHECK</Link></li>
                                    <li><Link to="/">BANK TRANSFER</Link></li>
                                    <li><Link to="/">CRYPTOCURRENCIES</Link></li>
                                    <li><Link to="/">SECURITIES/STOCK</Link></li>
                                    <li><Link to="/">VEHICLE</Link></li>
                                </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                <h2 className="text-center">HOW YOUR DONATION WILL BE USED</h2>
                                </div>
                                <div className="card-body bg-success-light donate-text">
                                <p>GiveWell (a.k.a. The Clear Fund) is a US <Link to="/"> federally-recognized tax-exempt 501(c)(3) organization</Link> and a registered public benefit organization (ANBI) in The Netherlands. US and Dutch donors are able to take tax deductions for donations to GiveWell to the extent permitted by law. <Link to="/">More information on the tax-deductibility of donations to our top charities</Link>, including for donors outside the US and The Netherlands.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                </main>
        </div>
    );
}

export default Donate;