import React from 'react';



function KnowledgeBase() {
    return (
        <div className="KnowledgeBase-page-main">
                KnowledgeBase components works
        </div>
    );
}

export default KnowledgeBase;