import React, { useState, useEffect } from 'react';
import Footer from './footer';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements
} from '@stripe/react-stripe-js';

import { loadStripe } from "@stripe/stripe-js";

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const stripe = loadStripe(
  "pk_test_51IYsIZIuHZgqJtbwerlznEkY5ZrCpQ5qLMGXU5gfIPahRcPgShxC0g0CFNCeT6SKiWzC1iG9bYa1yIntv9lUX3lX00h2dHjVo9"
);

function ProductCheckout() {
    const [shippingFirstName, setShippingFirstName] = useState(null as any);
    const [shippingLastName, setShippingLastName] = useState(null as any);
    const [shippingCompany, setShippingCompany] = useState(null as any);
    const [shippingStreetAddressOne, setShippingStreetAddressOne] = useState(null as any);
    const [shippingStreetAddressTwo, setShippingStreetAddressTwo] = useState(null as any);
    const [shippingCity, setShippingCity] = useState(null as any);
    const [shippingPostal, setShippingPostal] = useState(null as any);
    const [shippingState, setShippingState] = useState(null as any);
    const [billingFirstName, setBillingFirstName] = useState(null as any);
    const [billingLastName, setBillingLastName] = useState(null as any);
    const [billingCompany, setBillingCompany] = useState(null as any);
    const [billingStreetAddressOne, setBillingStreetAddressOne] = useState(null as any);
    const [billingStreetAddressTwo, setBillingStreetAddressTwo] = useState(null as any);
    const [billingCity, setBillingCity] = useState(null as any);
    const [billingPostal, setBillingPostal] = useState(null as any);
    const [email, setEmail] = useState(null as any);
    const [phone, setPhone] = useState(null as any);
    const [note, setNote] = useState(null as any);
    const [gift, setGift] = useState(null as any);
    const [coupen, setCoupen] = useState(null as any);
    const [newLetter, setNewLetter] = useState(false);
    const [terms, setTerms] = useState(false);
    const [shipped, setShipped] = useState(false);
    const [states, setStates] = useState(null as any);
    const [doCopy, setDoCopy] = useState(false);
    const [formSubmittedOnce, setFormSubmittedOnce] = useState(false);
    const [formValidated, setformValidated] = useState(false);
    const [showMonths, setShowMonths] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [showGift, setShowGift] = useState(false);
    const [showCoupen, setShowCoupen] = useState(false);
    const [formValidation, setFormValidation] = useState({
      shippingFirstName: false,
      shippingLastName: false
    })
    const copyAddress = (doCopy: boolean) => {
      console.log('doCopy', doCopy)
      if(doCopy) {
        setBillingFirstName(shippingFirstName)
        setBillingLastName(shippingLastName)
        setBillingCompany(shippingCompany)
        setBillingStreetAddressOne(shippingStreetAddressOne)
        setBillingStreetAddressTwo(shippingStreetAddressTwo)
        setBillingCity(shippingCity)
        setBillingPostal(shippingPostal)
        setDoCopy(doCopy)
        return
      }
      setBillingFirstName('')
      setBillingLastName('')
      setBillingCompany('')
      setBillingStreetAddressOne('')
      setBillingStreetAddressTwo('')
      setBillingCity('')
      setBillingPostal('')
      setDoCopy(doCopy)
      return
    }
    const submitPaymentForm = () => {
      console.log(shippingFirstName, shippingLastName, shippingState)
      formValidation.shippingFirstName = !shippingFirstName || shippingFirstName === '' ? true : false;
      formValidation.shippingLastName = !shippingLastName || shippingLastName === '' ? true : false;
      if(!formValidation.shippingFirstName && !formValidation.shippingLastName && formSubmittedOnce) {
        setformValidated(true)
      }
      console.log(formValidation)
      setFormValidation(formValidation)
      console.log(formValidation)

      setFormSubmittedOnce(true)
    }
    useEffect(() =>{
      setStates([
        {
          state: 'Alabama',
          value: 'AL'
        },
        {
          state: 'Alaska',
          value: 'AK'
        },
        {
          state: 'American Samoa',
          value: 'AS'
        },
        {
          state: 'Arizona',
          value: 'AZ'
        },
        {
          state: 'Arkansas',
          value: 'AR'
        },
        {
          state: 'California',
          value: 'CA'
        },
        {
          state: 'Colorado',
          value: 'CO'
        },
        {
          state: 'Connecticut',
          value: 'CT'
        },
        {
          state: 'Delaware',
          value: 'DE'
        },
        {
          state: 'District Of Columbia',
          value: 'DC'
        },
        {
          state: 'Federated States Of Micronesia',
          value: 'FM'
        },
        {
          state: 'Florida',
          value: 'FL'
        },
        {
          state: 'Georgia',
          value: 'GA'
        },
        {
          state: 'Guam',
          value: 'GU'
        },
        {
          state: 'Hawaii',
          value: 'HI'
        },
        {
          state: 'Idaho',
          value: 'ID'
        },
        {
          state: 'Illinois',
          value: 'IL'
        },
        {
          state: 'Indiana',
          value: 'IN'
        },
        {
          state: 'Iowa',
          value: 'IA'
        },
        {
          state: 'Kansas',
          value: 'KS'
        },
        {
          state: 'Kentucky',
          value: 'KY'
        },
        {
          state: 'Louisiana',
          value: 'LA'
        },
        {
          state: 'Maine',
          value: 'ME'
        },
        {
          state: 'Marshall Islands',
          value: 'MH'
        },
        {
          state: 'Maryland',
          value: 'MD'
        },
        {
          state: 'Massachusetts',
          value: 'MA'
        },
        {
          state: 'Michigan',
          value: 'MI'
        },
        {
          state: 'Minnesota',
          value: 'MN'
        },
        {
          state: 'Mississippi',
          value: 'MS'
        },
        {
          state: 'Missouri',
          value: 'MO'
        },
        {
          state: 'Montana',
          value: 'MT'
        },
        {
          state: 'Nebraska',
          value: 'NE'
        },
        {
          state: 'Nevada',
          value: 'NV'
        },
        {
          state: 'New Hampshire',
          value: 'NH'
        },
        {
          state: 'New Jersey',
          value: 'NJ'
        },
        {
          state: 'New Mexico',
          value: 'NM'
        },
        {
          state: 'New York',
          value: 'NY'
        },
        {
          state: 'North Carolina',
          value: 'NC'
        },
        {
          state: 'North Dakota',
          value: 'ND'
        },
        {
          state: 'Northern Mariana Islands',
          value: 'MP'
        },
        {
          state: 'Ohio',
          value: 'OH'
        },
        {
          state: 'Oklahoma',
          value: 'OK'
        },
        {
          state: 'Oregon',
          value: 'OR'
        },
        {
          state: 'Palau',
          value: 'PW'
        },
        {
          state: 'Pennsylvania',
          value: 'PA'
        },
        {
          state: 'Puerto Rico',
          value: 'PR'
        },
        {
          state: 'Rhode Island',
          value: 'RI'
        },
        {
          state: 'South Carolina',
          value: 'SC'
        },
        {
          state: 'South Dakota',
          value: 'SD'
        },
        {
          state: 'Tennessee',
          value: 'TN'
        },
        {
          state: 'Texas',
          value: 'TX'
        },
        {
          state: 'Utah',
          value: 'UT'
        },
        {
          state: 'Vermont',
          value: 'VT'
        },
        {
          state: 'Virgin Islands',
          value: 'VI'
        },
        {
          state: 'Virginia',
          value: 'VA'
        },
        {
          state: 'Washington',
          value: 'WA'
        },
        {
          state: 'West Virginia',
          value: 'WV'
        },
        {
          state: 'Wisconsin',
          value: 'WI'
        },
        {
          state: 'Wyoming',
          value: 'WY'
        }
      ])
      console.log('formSubmittedOnce', formSubmittedOnce, formValidated, shippingFirstName, shippingLastName)
      formValidation.shippingFirstName = formSubmittedOnce && !formValidated && (!shippingFirstName || shippingFirstName === '') ? true : false;
      formValidation.shippingLastName = formSubmittedOnce && !formValidated && (!shippingLastName || shippingLastName === '') ? true : false;
      console.log('2222222222', formValidation)
      if(formValidation.shippingFirstName || formValidation.shippingLastName) {
        setFormSubmittedOnce(false)
      }
      setFormValidation(formValidation)
    }, [formValidation])
    return (
        <div className="about-page-main">
            <main>
              <section className="donate-wraper pd-50">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="donate-title">Payment Lorem Ipsum Dolor -</h5>
                    </div>
                    <div className="col-md-12">
                      <div className="bg-color banner-post px-4">
                        <p className="mb-0 py-3">Returning customer? <a><u>Click here to login</u></a></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="donate-form pt-md-5">
                  <div className="container">
                    <div className="row d-flex justify-content-between my_row">
                      <div className="col-xl-5 col-lg-6 col-md-6">
                        <h4 className=" text_primery donate-title-sub">SHIPPING DETAILS</h4>
                        <small><span className="text-danger">*</span>required fields</small>
                        <div className="form-group mt-3 mb-3">
                          <label>First Name<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" defaultValue={shippingFirstName} onChange={(e) => setShippingFirstName(e.target.value)}/>
                          { formValidation.shippingFirstName && <label className="cutom-lable-2">First Name is required</label> }
                        </div>
                        <div className="form-group mt-3 mb-3">
                          <label>Last Name<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" defaultValue={shippingLastName} onChange={(e) => setShippingLastName(e.target.value)}/> 
                          { formValidation.shippingLastName && <label className="cutom-lable-2">Last Name is required</label> }
                        </div>
                        <div className="form-group mt-3 mb-3">
                          <label>Company</label>
                          <input type="text" className="form-control" defaultValue={shippingCompany} onChange={(e) => setShippingCompany(e.target.value)}/> 
                        </div>
                        <div className="form-group position-relative mt-3">
                          <label>Street Address Line 1<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" defaultValue={shippingStreetAddressOne} onChange={(e) => setShippingStreetAddressOne(e.target.value)}/>
                          <label className="cutom-lable">Street Address is required</label> 
                        </div>
                        <div className="form-group position-relative mt-3">
                          <label>Street Address Line 2</label>
                          <input type="text" className="form-control" defaultValue={shippingStreetAddressTwo} onChange={(e) => setShippingStreetAddressTwo(e.target.value)}/>
                        </div>
                        <div className="form-group  mb-3 mt-md-0">
                          <label>City<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" defaultValue={shippingCity} onChange={(e) => setShippingCity(e.target.value)}/>
                        </div>
                        <div className="form-group  mb-3 mt-md-0">
                          <label>State<span className="text-danger">*</span></label>
                          <select className="form-control" onChange={(e) => setShippingState(e.target.value)}>
                            { states?.map((s: any) => <option value={s.value}>{s.state}</option>) }
                          </select>
                        </div>
                        <div className="form-group">
                            <label>Postcode / ZIP<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" defaultValue={shippingPostal} onChange={(e) => setShippingPostal(e.target.value)}/> 
                        </div>
                        <h4 className=" text_primery donate-title-sub">CONTACT INFORMATION</h4>
                        <div className="form-group mt-3 mb-3">
                          <label>Email<span className="text-danger">*</span></label>
                          <input type="Email" className="form-control" defaultValue={email} onChange={(e) => setEmail(e.target.value)}/> <small>You will be able to create an account after purchasing</small> 
                        </div>
                        <div className="form-group mt-3">
                          <label>Phone <span className="text-danger">*</span> <small>For delivery-related queries</small></label>
                          <input type="text" className="form-control" defaultValue={phone} onChange={(e) => setPhone(e.target.value)}/> 
                        </div>
                        <h4 className=" text_primery donate-title-sub">BILLING DETAILS</h4>
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck3" onClick={() => copyAddress(!doCopy)}/>
                            <label className="form-check-label" htmlFor="exampleCheck3">Same as shipping address</label>
                          </div>
                        <div className="form-group mt-3 mb-3">
                          <label>First Name<span className="text-danger">*</span></label>
                          <input type="text" className="form-control"  defaultValue={billingFirstName} onChange={(e) => setBillingFirstName(e.target.value)}/> 
                        </div>
                        <div className="form-group mt-3 mb-3">
                          <label>Last Name<span className="text-danger">*</span></label>
                          <input type="text" className="form-control"  defaultValue={billingLastName} onChange={(e) => setBillingLastName(e.target.value)}/> 
                        </div>
                        <div className="form-group mt-3 mb-3">
                          <label>Company</label>
                          <input type="text" className="form-control"  defaultValue={billingCompany} onChange={(e) => setBillingCompany(e.target.value)}/> 
                        </div>
                        <div className="form-group position-relative mt-3">
                          <label>Street Address Line 1<span className="text-danger">*</span></label>
                          <input type="text" className="form-control"  defaultValue={billingStreetAddressOne} onChange={(e) => setBillingStreetAddressOne(e.target.value)}/>
                          <label className="cutom-lable">Street Address is required</label> 
                        </div>
                        <div className="form-group position-relative mt-3">
                          <label>Street Address Line 2</label>
                          <input type="text" className="form-control"  defaultValue={billingStreetAddressTwo} onChange={(e) => setBillingStreetAddressTwo(e.target.value)}/>
                        </div>
                        <div className="form-group  mb-3 mt-md-0">
                          <label>City<span className="text-danger">*</span></label>
                          <input type="text" className="form-control"  defaultValue={billingCity} onChange={(e) => setBillingCity(e.target.value)}/>
                        </div>
                        <div className="form-group  mb-3 mt-md-0">
                          <label>State<span className="text-danger">*</span></label>
                          <select className="form-control">
                          { states?.map((s: any) => <option value={s.value}>{s.state}</option>) }
                          </select>
                        </div>
                        <div className="form-group">
                            <label>Postcode / ZIP<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" defaultValue={billingPostal} onChange={(e) => setBillingPostal(e.target.value)}/> 
                        </div>
                        <h4 className=" text_primery donate-title-sub">ADDITIONAL OPTIONS</h4>
                        <div className="read_info">
                          <div className="form-group form-check mb-0">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={() => setShowNote(!showNote)}/>
                            <label className="form-check-label" htmlFor="exampleCheck1">Add a note to this order<span className="text-danger">*</span> </label>
                          </div>
                        </div>
                        { showNote && <div className="form-group mt-3 mb-3">
                          <label>Note</label>
                          <textarea className="form-control" defaultValue={note} onChange={(e) => setNote(e.target.value)}></textarea> 
                        </div> }
                        <div className="read_info">
                          <div className="form-group form-check mb-0">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={() => setShowGift(!showGift)} />
                            <label className="form-check-label" htmlFor="exampleCheck1">Is this a gift order?<span className="text-danger">*</span> </label>
                          </div>
                        </div>
                        { showGift && <div className="form-group mt-3 mb-3">
                          <label>Add a gift message</label>
                          <textarea className="form-control" defaultValue={gift} onChange={(e) => setGift(e.target.value)}></textarea> 
                        </div> }
                        <div className="read_info">
                          <div className="form-group form-check mb-0">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={() => setShowCoupen(!showCoupen)}/>
                            <label className="form-check-label" htmlFor="exampleCheck1">Apply a Coupon Code<span className="text-danger">*</span> </label>
                          </div>
                        </div>
                        { showCoupen && <div className="form-group mt-3 mb-3">
                          <label>Coupon Code</label>
                          <input type="text" className="form-control" defaultValue={coupen} onChange={(e) => setCoupen(e.target.value)}/> 
                        </div> }
                        <h6 className="text_primery ">PAYMENT METHOD</h6>
                        <div className="card credit-card">
                          <div className="card-body bg-white">
                          <Elements stripe={stripe}>
                            <label htmlFor="cardNumber">Card Number</label>
                            <CardNumberElement
                              id="cardNumber"
                              options={ELEMENT_OPTIONS}
                            />
                            <label htmlFor="expiry">Card Expiration</label>
                            <CardExpiryElement
                              id="expiry"
                              options={ELEMENT_OPTIONS}
                            />
                            <label htmlFor="cvc">CVC</label>
                            <CardCvcElement
                              id="cvc"
                              options={ELEMENT_OPTIONS}
                            />
                            </Elements>
                          </div>
                        </div>
                        <div className="read_info">
                          <div className="form-group form-check mb-0">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={() => setShowMonths(!showMonths)}/>
                            <label className="form-check-label" htmlFor="exampleCheck1">Pay in Equated Monthly Installments<span className="text-danger">*</span> </label>
                          </div>
                        </div>
                        { showMonths && <div className="form-group">
                          <label><input id="3months" name="installment" value="3" type="radio"/>3 Months</label>
                          <label><input id="6months" name="installment" value="6" type="radio"/>6 Months</label>
                          <label><input id="9months" name="installment" value="9" type="radio"/>9 Months</label>
                        </div> }
                        <div className="more-question mt-3">
                          <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck3" onClick={() => setTerms(!terms)}/>
                            <label className="form-check-label" htmlFor="exampleCheck3">I have read and agree to the website terms and conditions <span className="text-danger">*</span> </label>
                          </div>
                          <small>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a> privacy policy</a>.</small>
                          <div className="form-group form-check mt-3">
                            <input type="checkbox" className="form-check-input" id="exampleCheck4" onClick={() => setNewLetter(!newLetter)}/>
                            <label className="form-check-label" htmlFor="exampleCheck4">Check this box if you want to subscribe to our Newsletter </label>
                          </div>
                          <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck5" onClick={() => setShipped(!shipped)}/>
                            <label className="form-check-label" htmlFor="exampleCheck5">Note: The item will be shipped after May 31st the Earliest. </label>
                          </div>
                          <button type="button" className="btn btn-warning mt-4 px-5" onClick={() => submitPaymentForm()}>PLACE ORDER</button>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-6 payment-methods">
                        <div className="card">
                          <div className="card-body bg-success-light donate-text">
                            <h5 className="text-primary">YOUR ORDER</h5>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>The Control Module (Cost+)  × 1</td>
                                  <td>127</td>
                                </tr>
                                <tr>
                                  <td>The Control Module (Cost+)  × 1</td>
                                  <td>127</td>
                                </tr>
                                <tr>
                                  <td><strong>Shipping</strong></td>
                                  <td>15</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td><strong>Total</strong></td>
                                  <td><strong>269</strong></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h2>OTHER PAYMENT METHODS</h2>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li><a className="active" href="donate-credit-card.html">CREDIT CARD</a></li>
                              <li><a href="donate-paypal-1.html">PAYPAL</a></li>
                            </ul>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h2 className="text-center">DONATE TO HELP THE POOR</h2>
                          </div>
                          <div className="card-body bg-success-light donate-text">
                            <p>GiveWell (a.k.a. The Clear Fund) is a US <a> federally-recognized tax-exempt 501(c)(3) organization</a> and a registered public benefit organization (ANBI) in The Netherlands. US and Dutch donors are able to take tax deductions for donations to GiveWell to the extent permitted by law. <a>More information on the tax-deductibility of donations to our top charities</a>, including for donors outside the US and The Netherlands.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <Footer/>
        </div>
    );
}

export default ProductCheckout;