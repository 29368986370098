import React from 'react';
import { Link } from 'react-router-dom';
import DashboardTopMenu from './dashboardTopMenu';
import SideBar from './sidebar';



function Dashboard() {
    
    return (
        <div className="dashboard-page-main">
            <div id="wrapper">
                <SideBar />
             <section id="content-wrapper" className="user-dashboard">
            <Link to="/" className="navbar-brand d-lg-none" id="sidebar-toggle"><i className="fa fa-bars" /> Menu</Link>
           
                <DashboardTopMenu></DashboardTopMenu>
         
                <div className="row account-stats mt-4">
                    <div className="col-lg-12">
                    <div className="card user-details mb-3">
                        <div className="card-body py-4">
                        <div className="row align-items-center">
                            <div className="col-md-12 mb-4">
                            <h4 className="title-border">Account Stats</h4>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                            <h2>04</h2>
                            <p>Total Orders</p>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                            <h2>04</h2>
                            <p>Order Pending</p>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                            <h2>05</h2>
                            <p>Order Shipped</p>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                            <h2>04</h2>
                            <p>Order placed</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row edit-profile">
                <div className="col-lg-12">
                <div className="card user-details mb-3">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                        <h4 className="title-border">Update/Edit Profile</h4>
                        </div>
                        <div className="col-md-3 text-center">
                        <img className="avatar" src="assets/images/user.png" alt="user" />
                        <button className="d-block">Change Profile image</button>
                        </div>
                        <div className="col-md-3">
                        <h5 className="mt-0">Name:
                        </h5>
                        <h6>Jonathan Doe <span className="fa fa-edit ml-3" />
                        </h6>
                        </div>
                        <div className="col-md-3">
                        <h5 className="mt-0">Address:
                        </h5>
                        <p>4726 Mandan Road
                            Columbia, Missouri 65741.
                            United States of America.
                            <span className="fa fa-edit ml-3" />
                        </p>
                        </div>
                        <div className="col-md-3">
                        <h5 className="mt-0">Phone No:
                        </h5>
                        <h6>(+1)760-304-0757 <span className="fa fa-edit ml-3" />
                        </h6>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>

        </div>
    );

}
export default Dashboard;