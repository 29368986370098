import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer';



function Contact() {
    return (
        <div className="contact-page-main">

        {/* Banner section */}
        <section className="banner-section py-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Contact</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section bg-success-light"> 
          <div className="container">
            <div className="row">
              <div className="col-md-12 testATag">
                
                <h2 className="text-center">Let's Get in Touch!</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pr-lg-5">
                <p className="mb-4 pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div className="row">
                  <div className="col-6">
                    <div className="media">
                      <i className="fa fa-phone" />
                      <div className="media-body ml-2">
                        <h6>Call Us</h6>
                        <a href="tel:0123-456-7890">0123-456-7890</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 pl-0">
                    <div className="media">
                      <i className="fa fa-envelope" />
                      <div className="media-body ml-2">
                        <h6>Email Us</h6>
                        <a href="mailto:mail@website.com">mail@website.com</a>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="img-fluid mt-5" src="assets/images/contact.png" alt="contact" />
              </div>
              <div className="col-md-6 pt-4 pt-md-0">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6 mb-3">
                      <label>Name*</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>Email*</label>
                      <input type="email" className="form-control" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 mb-3">
                      <label>Phone*</label>
                      <input type="email" className="form-control" />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>Subject</label>
                      <input type="password" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label>Message*</label>
                    <textarea className="form-control" rows={6} defaultValue={""} />
                  </div>
                  <button type="submit" className="btn btn-warning px-5">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="link-section"> 
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h3 className="mb-4 pb-2">Important Links</h3>
                <Link to="/" className="btn btn-warning mx-sm-3 mb-3">COMMUNICATION</Link>
                <Link to="/" className="btn btn-warning mx-sm-3 mb-3">INVESTOR INFORMATION</Link>
                <Link to="/" className="btn btn-warning mx-sm-3 mb-3 px-5">COMPANIES</Link>
                <Link to="/" className="btn btn-warning mx-sm-3 mb-3">KNOWLEDGEBASE</Link>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    );
}

export default Contact