import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './footer';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const user=  JSON.parse(localStorage.getItem('user') as string)
        if(user && user.accessToken) {
            window.location.href = '/dashboard'
        }
    }, [])

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const response = await axios.post(`https://ecommerce-backend-e4eu2jwfaq-uw.a.run.app/api/auth/login`, {
            "email": email,
            "password": password
        })
        if(response.data.accessToken){
            localStorage.setItem('user', JSON.stringify(response.data))
            window.location.href = '/dashboard'
        }

    }

    return (
        <div className="login-page-main">
              <section className="account-sign">
                <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-12">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center px-0">
                        <img className="img-fluid" src="assets/images/signin.png" alt="signin" />
                        </div>
                        <div className="col-md-6 bg-success-light">
                        <div className="sign-block">
                            <h5 className="text-primary mb-4 pb-2">SIGN IN TO YOUR ACCOUNT</h5>
                            <div className="row">
                            <div className="col-md-12 col-lg-10 col-xl-8">
                                <form>
                                <div className="form-group mb-3">
                                    <label>Enter Email</label>
                                    <input type="email" className="form-control" defaultValue={email} onChange={(event: any) => setEmail(event.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label>Enter Password</label>
                                    <input type="password" className="form-control" defaultValue={password} onChange={(event: any) => setPassword(event.target.value)} />
                                </div>
                                <button type="submit" className="btn btn-warning px-5 mt-3 mt-md-0" onClick={handleSubmit}>SIGN IN</button>
                                <p className="text-primary mt-3">Forgot Password?</p>
                                </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default Login;