
import React, { useState, useEffect } from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import './CheckoutForm.css';
import axios from 'axios';

import {logEvent} from '../../Utils';
import { Link } from "react-router-dom";
import DashboardTopMenu from "./dashboardTopMenu";
import {environment} from './../../environments/dev-env'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
const API_URL = environment.baseUrl;

function CheckoutForm() {
    const elements = useElements();
    const stripe = useStripe();
    const [postal, setPostal] = useState('');
    const [disablePay, setDisablePay] = useState(true);

    const [successLabel, showSuccessLabel] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(null);
    const [secretId, setSecretId] = useState('')
   
    useEffect(() => {

     getPaymentId();
      
    },[]);
     
    
          
    async function getPaymentId(){
    
      const user=  JSON.parse(localStorage.getItem('user') as string)

      const { data: paymentID }: any = await axios.post(`${API_URL}/payments`, {
         "amount": 10,
         "currency": 'USD'
      }, {
      'headers': {
        'Authorization': `Bearer ${user.accessToken}`
      }
     } );
     await getClientSeceret(paymentID.payment_id);
     setDisablePay(false)
     
     
   }  

   async function getClientSeceret(id: any){
    const user=  JSON.parse(localStorage.getItem('user') as string)

    const response: any = await axios.get(`${API_URL}/payments/${id}/get_client_secret`, {
      'headers': {
        'Authorization': `Bearer ${user.accessToken}`
      }
    });
    setSecretId(response['data']['client_secret']);
   }
  
    const handleSubmit = async (event: { preventDefault: () => void; }) => {
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      const cardElement = elements.getElement(CardNumberElement) as any;
  
      const response = await stripe.confirmCardPayment(secretId, {
        payment_method:  { card: cardElement }
      
      }).then( res=>{
        console.log("hehehe");
        showSuccessLabel(true);
      });
        console.log("confirmCardPayment", response);
        showSuccessLabel(true)
        elements.getElement(CardNumberElement)?.clear()
        elements.getElement(CardExpiryElement)?.clear()
        elements.getElement(CardCvcElement )?.clear()
        setPostal('')
      
    };

  return (
    <div>
      <DashboardTopMenu></DashboardTopMenu>
     { successLabel && <div className='label-for-payment-success' style={{marginLeft: 515}}><label>Your payment has successfully submitted</label></div> }
    <form onSubmit={handleSubmit}>

    <label htmlFor="cardNumber">Card Number</label>
    <CardNumberElement
      id="cardNumber"
      onBlur={logEvent('blur')}
      onChange={logEvent('change')}
      onFocus={logEvent('focus')}
      onReady={logEvent('ready')}
      options={ELEMENT_OPTIONS}
    />
    <label htmlFor="expiry">Card Expiration</label>
    <CardExpiryElement
      id="expiry"
      onBlur={logEvent('blur')}
      onChange={logEvent('change')}
      onFocus={logEvent('focus')}
      onReady={logEvent('ready')}
      options={ELEMENT_OPTIONS}
    />
    <label htmlFor="cvc">CVC</label>
    <CardCvcElement
      id="cvc"
      onBlur={logEvent('blur')}
      onChange={logEvent('change')}
      onFocus={logEvent('focus')}
      onReady={logEvent('ready')}
      options={ELEMENT_OPTIONS}
    />
    <label htmlFor="postal">Postal Code</label>
    <input
      id="postal"
      required
      placeholder="12345"
      value={postal}
      onChange={(e) => {
        setPostal(e.target.value);
      }}
    />
    {/* {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>} */}
    {/* {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
    <button className="payBtn" type="submit" disabled={disablePay}>
      Pay
    </button>
  </form>
    
    </div>
  );


}

export default CheckoutForm;