import React from 'react';
import { Link } from 'react-router-dom';
import SideBar from './sidebar';



function Billing() {
    return (
        <div className="billing-page-main">
               <div id="wrapper">
                <SideBar />
                <main>
                <section id="content-wrapper" className="user-dashboard">
          <button className="navbar-brand d-lg-none" id="sidebar-toggle"><i className="fa fa-bars" /> Menu</button>
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">My Account </Link></li>
                <li className="breadcrumb-item"><Link to='/'>Jonathan Doe</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Logout</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card mt-4">
              <div className="card-body">
                <h5 className="mb-3">Update/Edit Billing</h5>
                <div className="form-check mb-4">
                  <input className="form-check-input" type="checkbox" id="shipping" />
                  <label className="form-check-label ml-2" htmlFor="shipping">
                    Same as Shipping
                  </label>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-2">
                      <label>First Name *</label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-2 text-md-right">
                      <label>Last Name *</label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label>Company</label>
                    </div>
                    <div className="col-md-10">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label>Address *</label>
                    </div>
                    <div className="col-md-10">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label>City</label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-2 text-md-right">
                      <label>Country/Region</label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label>Phone *</label>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-2 offset-md-1">
                      <div className="form-group mt-3 mt-md-0">
                        <select className="form-control">
                          <option>select</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-warning mr-2">Save</button>
                      <button type="button" className="btn btn-light">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
                </main>
            </div>
        </div>
    );
}

export default Billing;