import React from 'react';
import { Link } from 'react-router-dom';
import SideBar from './sidebar';



function OrderHistory() {
    return (
        <div className="orderhistory-page-main">
            <div id="wrapper">
                <SideBar />
                <main>
              <section id="content-wrapper" className="user-dashboard">
                  <button className="navbar-brand d-lg-none" id="sidebar-toggle"><i className="fa fa-bars" /> Menu</button>
                  <div className="row">
                    <div className="col-md-12">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">My Account </Link></li>
                          <li className="breadcrumb-item"><Link to="/">Jonathan Doe</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Logout</li>
                        </ol>
                      </nav>
                    </div>  
                    </div>  
                  
                  <div className="row">
                  <div className="col-lg-12">
                    <div className="card mt-4">
                      <div className="card-body px-0">
                        <h5 className="mb-5 pl-4">Order History</h5>
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="thead-light">
                              <tr>
                                <th  >Order ID</th>
                                <th  >Product</th>
                                <th className="text-center">Quantity</th>
                                <th  >Price</th>
                                <th className="text-center"  >Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>#746815</td>
                                <td>Product 1</td>
                                <td className="text-center">1</td>
                                <td className="text-paid">$85.40</td>
                                <td className="text-center"><button type="button" className="btn btn-success paid-btn">Paid</button></td>
                              </tr>
                              <tr>
                                <td>#746815</td>
                                <td>Product 2</td>
                                <td className="text-center">1</td>
                                <td className="text-success">$85.40</td>
                                <td className="text-center"><button type="button" className="btn btn-success">Shipped</button></td>
                              </tr>
                              <tr>
                                <td>#746815</td>
                                <td>Product 3</td>
                                <td className="text-center">1</td>
                                <td className="text-cancel">$85.40</td>
                                <td className="text-center"><button type="button" className="btn btn-danger">Cancelled</button></td>
                              </tr>
                              <tr>
                                <td>#746815</td>
                                <td>Product 4</td>
                                <td className="text-center">1</td>
                                <td className="text-pending">$85.40</td>
                                <td className="text-center"><button type="button" className="btn btn-danger pending-btn">Pending</button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  
              </section>
       
                </main>
            </div>
          </div>
    );
}

export default OrderHistory;