import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'


// axios.interceptors.request.use(request=>{
//   console.log(request)
//   const user=  JSON.parse(localStorage.getItem('user') as string)
//   request.headers.Authorization=`Bearer ${user.accessToken}`
//   return request
// })

axios.interceptors.response.use(response=>{
  console.log(response)
  return response
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
