import React from 'react';
import Footer from './footer';



function About() {
    return (
        <div className="about-page-main">
            <main>
        {/* Banner section */}
        <section className="banner-section py-5">
          <div className="container">
            <div className="row Companies">
              <div className="col-md-12">
                <h2>Companies</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="we_design">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <img src="assets/images/company_1.png" className="img-fluid w-100 mb-3" alt="company" />
                <img src="assets/images/company_2.png" className="img-fluid w-100 mb-4" alt="company" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="we_desing_content">
                  <h1>We design and develop <br className="d-none d-lg-block" /> web Based lorem sit <br className="d-none d-lg-block" /> company 01.</h1>
                  <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                  <ul className="mb-5">
                    <li>01. Sed ut perspiciatis unde omnis</li>
                    <li> 02. Sed ut perspiciatis unde omnis iste natus error iste natus error</li>
                    <li> 03. Sed ut perspiciatis unde omnis iste natus error </li>
                    <li>04. Sed ut perspiciatis unde omnis iste</li>
                  </ul>
                </div>
                <button type="button" className="btn btn-warning ml-4">VISIT NOW</button>
              </div>
            </div>
          </div>
        </section>
        <section className="we_design bg_success">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-md-5">
                <div className="we_desing_content">
                  <h1>We design and develop world-cla applications company 02.</h1>
                  <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <button type="button" className="btn btn-warning">VISIT NOW</button>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row gallery mt-4">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 align-self-center pr-0">
                    <img src="assets/images/gallery.png" className="img-fluid w-100 mb-3" alt="gallery" />
                    <img src="assets/images/blogs_1.png" className="img-fluid w-100 mb-3" alt="gallery" /> 
                    <img src="assets/images/gallery.png" className="img-fluid w-100 mb-3" alt="gallery" />
                    <img src="assets/images/blogs_1.png" className="img-fluid w-100 mb-3" alt="gallery" /> 
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <img src="assets/images/gallery_right.png" className="img-fluid w-100" alt="gallery" /> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="take_quike">
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-12">
                <h1>Take quick overview of awesome features of our Product</h1>
                <button type="button" className="btn btn-warning">Explore menu</button>
              </div>
            </div>
          </div>
        </section>
        <section className="we_design bg_success">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-lg-6 col-md-6 col-sm-12"> 
                <img src="assets/images/ready.png" className="img-fluid w-100 mb-4 mb-md-0" style={{boxShadow: 'none'}} alt="ready" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="we_desing_content">
                  <h1>Ready to join with us and work with us?</h1>
                  <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <button type="button" className="btn btn-warning mb-3">Explore menu</button>
              </div>
            </div>
          </div>
        </section>
      </main>
            <Footer/>
        </div>
    );
}

export default About;