import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {Home, TopMenuBar, About, Contact, Login, Company, Investor, KnowledgeBase , Donate, Cart, ProductCheckout } from "./components";
import { Dashboard, PaymentMethod, Shipping, Billing, OrderHistory } from './components/dashboard';


function App() {

  return ( 
    <div className="App">
      <Router>
        <TopMenuBar/>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/contact" exact component={() => <Contact />} />
          <Route path="/login" exact component={() => <Login />} />
          <Route path="/company" exact component={() => <Company />} />
          <Route path="/investor" exact component={() => <Investor />} />
          <Route path="/knowledge" exact component={() => <KnowledgeBase />} />
          <Route path="/donate" exact component={() => <Donate />} />
          <Route path="/cart" exact component={() => <Cart />} />
          <Route path="/dashboard" exact component={() => <Dashboard />} />
          <Route path="/paymentMethod" exact component={() => <PaymentMethod />} />
          <Route path="/shipping" exact component={() => <Shipping />} />
          <Route path="/billing" exact component={() => <Billing />} />
          <Route path="/orderhistory" exact component={() => <OrderHistory />} />
          <Route path="/productcheckout" exact component={() => <ProductCheckout />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
