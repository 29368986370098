import React from 'react';
import { Link } from 'react-router-dom';



function SideBar() {
    return (
        <div className="sidebar-page-main">
          
            <aside id="sidebar-wrapper">
                <button className="d-md-none" id="close"><i className="fa fa-times" /></button>
                <ul className="sidebar-nav">
                <li className="active">
                <Link to="/dashboard" href="dashboard.html">Home</Link>
                </li>
                <li> 
                <Link to="/shipping">Shipping</Link>
                </li>
                <li>
                <Link to="/billing">Billing</Link>
                </li>
                <li>
                <Link to="/paymentMethod">Payment Method </Link>
                </li>
                <li>
                <Link to="/orderhistory">Order History</Link>
                </li>
                {/* <li>
                <Link to="/shipping">Reset Password</Link>
                </li> */}
                {/* <li>
                <Link to="/shipping">Two-factor Authentication</Link>
                </li> */}
            </ul>
            </aside>
          </div>
    );

}
export default SideBar;