import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer';



function Home() {
    return (
        <div className="home-page-main">
        {/* Banner section */}
        <section className="home-section text-left">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-8 col-md-7">
                <h1>Lorem ipsum dolor sit amet consetetur sadipscing </h1>
                <Link to="/cart" className="btn btn-warning mt-3 mt-md-5">Buy Now</Link>
                <small className="d-block mt-2 pl-2 ml-1">Starting From $100</small>
              </div>
              <div className="col-sm-4 col-md-5 text-center text-md-right mt-5 mt-md-0">
                <img className="img-fluid" src="assets/images/product.png" alt="product" />
              </div>
            </div>
          </div>
        </section>
        {/* Membership */}
        <section className="explore-section">
          <div className="container">
            <div className="row">
              <div className="col-md-5 text-center">
                <img className="img-fluid" src="assets/images/menu.png" alt="menu" />
              </div>
              <div className="col-md-7">
                <h2>Your Hero membership is a complete, all-access pass to life-changing medication management.</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="media">
                      <i className="fa fa-star" />
                      <div className="media-body">
                        Lorem ipsum dolor sit amet
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="media">
                      <i className="fa fa-star" />
                      <div className="media-body">
                        Lorem ipsum dolor sit amet
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="media">
                      <i className="fa fa-star" />
                      <div className="media-body">
                        Lorem ipsum dolor sit amet
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="media">
                      <i className="fa fa-star" />
                      <div className="media-body">
                        Lorem ipsum dolor sit amet
                      </div>
                    </div>
                  </div>
                </div>
                <Link to="/" className="btn btn-warning mt-3 mt-md-5">Explore Menu</Link>
              </div>
            </div>
          </div>
        </section>
        {/* Our Service */}
        <section className="banner-section our-service mt-0" style={{backgroundImage: 'url(assets/images/summer-rest.png)'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Lorem ipsum dolor sit amet <br className="d-none d-md-block" /> consetetur sadipscing </h2>
              </div>
              <div className="col-md-4">
                <div className="service-icon"><i className="fa fa-heart" /></div>
                <h4>Lorem Ipsum Dolor Sit </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut </p>
              </div>
              <div className="col-md-4">
                <div className="service-icon"><i className="fa fa-heart" /></div>
                <h4>Lorem Ipsum Dolor Sit </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut </p>
              </div>
              <div className="col-md-4">
                <div className="service-icon"><i className="fa fa-heart" /></div>
                <h4>Lorem Ipsum Dolor Sit </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut </p>
              </div>
            </div>
          </div>
        </section>
        <section className="hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 text-center">
                <img className="img-fluid" src="assets/images/caregiver-holding.png" alt="caregiver" />
              </div>
              <div className="col-md-6 text-center text-md-left">
                <h2>Your Hero membership is a complete</h2>
                <h6>More than what you</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <h6>Impossible Is Nothing</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <h6>Let us handle what you need</h6>
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="member-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <h2 className="mb-3">Your Hero membership is a complete, all-access</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a  className="btn btn-warning mt-3">Explore Menu</a>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <img className="img-fluid" src="assets/images/access.png" alt="access" />
              </div>
            </div>
          </div>
        </section>
        {/* How work */}
        <section className="how-work">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>How it works </h2>
              </div>
              <div className="col-md-4">
                <img className="img-fluid" src="assets/images/how-work.png" alt="how-work" />
                <h4>Use the Hero app to program any pill regimen, simple or complex</h4>
                <p>Hero will notify you when it’s pill time as often as needed. </p>
              </div>
              <div className="col-md-4">
                <img className="img-fluid" src="assets/images/how-work.png" alt="how-work" />
                <h4>Use the Hero app to program any pill regimen, simple or complex</h4>
                <p>Hero will notify you when it’s pill time as often as needed. </p>
              </div>
              <div className="col-md-4">
                <img className="img-fluid" src="assets/images/how-work.png" alt="how-work" />
                <h4>Use the Hero app to program any pill regimen, simple or complex</h4>
                <p>Hero will notify you when it’s pill time as often as needed. </p>
              </div>
            </div>
          </div>
        </section>
        {/* Order */}
        <section className="banner-section order-section text-left mt-0" style={{backgroundImage: 'url(assets/images/order-bg.png)'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h2>Order your <span className="text-primary">Pill <br className="d-md-block" /> Dispenser </span> now </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a  data-toggle="modal" data-target="#exampleModal" className="btn btn-warning mt-3">Buy Now</a>
              </div>
              <div className="col-md-7 px-0">
                <img className="img-fluid d-sm-none" src="assets/images/order-1.png" alt="order" />
              </div>
            </div>
          </div>
        </section>
        <section className="note-section text-center">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9">
                <p className="mb-0"> 
                  <strong>Note: Pill Dispenser Modules will not shipped before 31st May the earliest. </strong>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et <span>dolore magna aliqua.</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Faq */}
        <section className="section-faq">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h2 className="text-center mb-3" style={{fontFamily: '"Source Serif Pro", serif'}}>Frequently Asked Questions</h2>
                <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
              </div>
              <div className="col-md-12">
                <div className="accordion pt-3" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed active" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          What are the features of Pill Dispenser 0?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          What are the features of Pill Dispenser 0?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          What are the features of Pill Dispenser 0?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          What are the features of Pill Dispenser 0?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          What are the features of Pill Dispenser 0?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingSix">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          What are the features of Pill Dispenser 0?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    );
}

export default Home;