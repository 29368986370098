/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';

function TopMenuBar(){

    const [showSignInButton, setShowSignInButton] = useState(true)

    useEffect(() => {
        const user=  JSON.parse(localStorage.getItem('user') as string)
        if(user && user.accessToken) {
            setShowSignInButton(false)
        }
    }, [])

 

    return(
        <header>
            <div className="top-menu-main">    
                <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                <Link to="/" className="navbar-brand">
                    <img src={process.env.PUBLIC_URL + './assets/images/logo.png'} alt="logo"/>
                </Link>
                
                <a className="nav-link nav-link-bg position-relative d-lg-none pr-0">
                <i className="icon-cart"></i>
                <span className="badge badge-pink">2</span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link" >Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link" >About</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/donate" className="nav-link" >Donate</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className="nav-link" >Contact</Link>
                        </li>
                    </ul>
                    { showSignInButton && <ul className="navbar-nav d-flex align-items-center">
                        <li className="nav-item pl-lg-4">
                            <Link to="/login" className="btn btn-outline-primary">
                                Sign In 
                            </Link>
                        </li>
                    </ul> }
                    { !showSignInButton && <ul className="navbar-nav d-flex align-items-center">
                        <li  className="nav-item pl-lg-4">
                            <Link to="/login" className="btn btn-outline-primary">
                               My Account
                            </Link>
                        </li>
                    </ul> }
                </div>
                </div>
            </nav>
            </div>
        </header>
    );
}
export default TopMenuBar;