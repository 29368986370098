import React from "react";
import { Link } from "react-router-dom";

function DashboardTopMenu() {
    
    function logout(){
        localStorage.removeItem('user');
        window.location.href = '/login';
  
    }
    
    return (
<div className="row">
    <div className="col-md-12">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">My Account </Link></li>
            <li className="breadcrumb-item"><Link to="/">Jonathan Doe</Link></li>
            <li className="breadcrumb-item active" aria-current="page" onClick={logout}>Logout</li>
            </ol>
        </nav>
    </div>
</div>
    );
}

export default DashboardTopMenu;