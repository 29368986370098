
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



function Cart() {
  const [products, setProduct] = useState(null as any)
  const [refresh, setRefresh] = useState(true)
  const [totalPrice, setTotalPrice] = useState(0)
  const updateQuantity = (index: number, type: string) =>  {
    if(products[index].quantity === 1 && type === 'decrement') {
       return setProduct(products)
    }
    if(type === 'increment') {
      products[index].quantity++;
    } else {
      products[index].quantity--;
    }
    setRefresh(true)
    return setProduct(products)
  }
  const removeProduct = (index: number) => {
    products.splice(index, 1)
    setProduct(products)
    setRefresh(true)
  }
  useEffect(() => {
    setProduct(products || [
      {
        productImage: 'assets/images/cart-img.png',
        productName: 'Product Name',
        productDescripition: 'Product Description',
        quantity: 2,
        unitPrice: 127
      },
      {
        productImage: 'assets/images/cart-img.png',
        productName: 'Product Name',
        productDescripition: 'Product Description',
        quantity: 2,
        unitPrice: 127
      }
    ])
    console.log('productsproductsproducts', products)
    setTotalPrice(products?.map((p: any) => p.unitPrice * p.quantity).reduce((a: number, b: number) => a + b, 0))
    setRefresh(false)
  }, [refresh])
    return (
        <div className="about-page-main">
               <main>
                  <section className="shopping-cart">
              <div className="container">
                <h5><strong>Shopping Cart</strong></h5>
                <div className="shopping-cart-list">
                  <div className="table-responsive">
                    <br /><table className="table">
                      <thead>
                        <tr>
                          <th >PRODUCT</th>
                          <th className="text-center">QUANTITY</th>
                          <th className="text-center">UNIT PRICE</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          products?.map((p: any, i: number) => 
                          <tr className="bg-clr">
                            <td>
                              <div className="media d-flex align-items-center">
                                <img src={p.productImage} alt="" />
                                <div className="media-bod ml-5">
                                  <h5 className="text-primary">{p.productName}</h5>
                                  <p>{p.productDescripition}</p>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="cont-plus">
                                <div className="input-group number-spinner justify-content-center">
                                  <span className="input-group-btn">
                                    <button className="btn btn-default px-2" data-dir="dwn" onClick={() => updateQuantity(i, 'decrement')}><span className="fa fa-minus"/></button>
                                  </span>
                                  <input type="text" className="form-control text-center" value={p.quantity} />
                                  <span className="input-group-btn">
                                    <button className="btn btn-default px-2" data-dir="up" onClick={() => updateQuantity(i, 'increment')}><span className="fa fa-plus" /></button>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">{p.unitPrice}</td>
                            <td>{p.unitPrice * p.quantity} <span className="fa fa-close pl-3 text-danger" onClick={() => removeProduct(i)}/></td>
                          </tr>
                          )
                        }
                        </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-7 offset-md-5">
                      <hr />
                      <div className="row">
                        <div className="col-6">
                          <p><strong>Selected Item <span className="ml-3"> {products?.length}</span> </strong></p>
                        </div>
                        <div className="col-2">
                          <p><strong>TOTAL</strong></p>
                        </div>
                        <div className="col-3 text-center pr-md-5">
                          <p><strong>{totalPrice}</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-center text-sm-left mt-5 pb-4">
                  <div className="col-sm-6">
                    <Link to="/" className="text-gray" ><i className="fa fa-angle-left mr-2" /> <small>Continue Shopping</small></Link>
                  </div>
                  <div className="col-sm-6 text-sm-right">
                    <Link to="/productcheckout" className="btn btn-warning mt-4 mt-sm-0">
                      PROCEED TO CHECKOUT
                    </Link>
                  </div>
                </div>
              </div>
            </section>
              </main>
        </div>
    );
}

export default Cart;

