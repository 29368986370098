import React from 'react';
import Footer from './footer';



function Investor() {
    
    return (
        <div className="about-page-main">
               <main>
                    {/* Banner section */}
                    <section className="banner-section py-5">
                    <div className="container">
                        <div className="row Companies">
                        <div className="col-md-12">
                            <h2>Investors</h2>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="investor_design">
                    <div className="container-fluid p-md-0">
                        <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <img src="assets/images/investor.png" className="img-fluid w-100" alt="" />
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="we_desing_content">
                            <h1>We design and develop <br /> web Based lorem sit company 01.</h1>
                            <p className="mb-3 mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="take_quike">
                    <div className="container  py-3">
                        <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-6">
                            <div className="we_desing_content">
                            <h1 className="text-left">Design and develop web Based lorem sit company</h1>
                            <p className="mb-3 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center">
                            <img src="assets/images/investor_1.png" className="img-fluid " alt="" />
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="web_based">
                    <div className="container we_desing_content">
                        <h1 className="text-left col-lg-7 col-md-10 pl-0 mb-4">We design and develop web Based lorem sit company</h1>
                        <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        </div>
                    </div>
                    </section>
                    <img src="assets/images/investor_2.png" className="img-fluid w-100" alt="investor" />
                    <section className="investor_design investor_design_one">
                    <div className="container-fluid p-lg-0">
                        <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <img src="assets/images/blogs_1.png" className="img-fluid w-100" alt="investor" />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="we_desing_content">
                            <h1>We design and develop web Based lorem sit company 01.</h1>
                            <p className="mb-3 mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                            </div>
                            <div className="row design">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="deign_content">
                                <h5>220%</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="deign_content">
                                <h5>$100</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="investor_design">
          <div className="container-fluid we_desing_content p-md-0">
            <div className="row justify-content-center ml-0 w-100 mr-0">
              <div className="col-lg-1 col-md-6">
              </div>
              <div className="col-lg-8 col-md-12">
                <h1>We design and develop web Based lorem <br />sit company</h1>
              </div>
              <div className="col-lg-3 col-md-6 pr-md-0">
              </div>
            </div>
            <div className="row align-items-center justify-content-end">
              <div className="col-lg-4 col-md-6">
                <div className="we_desing_content ml-auto">
                  <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  <button type="button" className="btn btn-warning mt-3 mb-5 my-md-5">CONTACT US</button>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 pr-md-0">
                <img src="assets/images/investor_3.png" className="img-fluid w-100" alt="investor" />
              </div>
            </div>
          </div>
        </section>
            </main>
            <Footer/>
        </div>
    );
}

export default Investor;