import React from "react";
import CheckoutForm from './CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SideBar from "./sidebar";



function PaymentMethod(){
    const stripe = loadStripe(
        "pk_test_51IYsIZIuHZgqJtbwerlznEkY5ZrCpQ5qLMGXU5gfIPahRcPgShxC0g0CFNCeT6SKiWzC1iG9bYa1yIntv9lUX3lX00h2dHjVo9"
    );

    return (
       
            <div className="stripe-integration">
                 <main>
                    <SideBar />
                    <Elements stripe={stripe}>
                        <CheckoutForm />
                    </Elements>
                </main>
            </div>

    );
 
}

export default PaymentMethod;